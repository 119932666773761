import Multiselect from 'multiselect-react-dropdown';
import React from "react";

export interface Option{
    name: string,
    id: number
}

interface IProps{
    options: Option[]
    onChange?: (options: Option[]) => void,
    placeholder: string
}
interface IState{
    options: Option[]
}

export default class MultiSelect extends React.Component<IProps, IState>{

    static defaultProps = {
        placeholder: "Select"
    }

    constructor(props: IProps){
        super(props);

        this.state = {
            options: this.props.options
        };

        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    render(){
        return (
            <div className="multiOptionSelect col-12 col-lg-10" style={{
                overflow: "visible",
                height: "100%",
                marginTop: "0.5rem",
            }}>
                <Multiselect
                    style={{
                        overflow: "visible",
                    }}
                    placeholder={this.props.placeholder}
                    options={this.state.options} // Options to display in the dropdown
                    onSelect={this.onSelect} // Function will trigger on select event
                    onRemove={this.onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                />
            </div>
        );
    }


    onSelect(selectedList: Option[], selectedItem: Option) {
        if(this.props.onChange){
            this.props.onChange(selectedList);
        }
        return {};
    }

    onRemove(selectedList: Option[], removedItem: Option) {
        if(this.props.onChange){
            this.props.onChange(selectedList);
        }
        return {};
    }

}
