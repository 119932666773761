import React from 'react';
import './directions.css';
import "dotenv/config";
import FloatingLabel from "react-bootstrap-floating-label";
import {GetJSONComponent} from "../utility/getJSON";
import Code from '../code/Code';

interface IProps{}
interface IState{
    origin?: string,
    destination?: string,
    originLat?: string,
    originLng?: string,
    destinationLat?: string,
    destinationLng?: string,
    coordinates: boolean
}

const initialOrigin = "7777 Royal Oak Ave, Burnaby, BC V5J 4K2";
const initialDestination = "4361 Kingsway, Burnaby, BC V5H 1Z9";

const initialOriginCoord = ["49.213167", "-122.988492"];
const initialDestinationCoord = ["49.23135", "-123.006893"];

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const query = `${serverPrefix}/api/directions/json?key=`;

export default class Directions extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);

        this.state = {
            origin: initialOrigin,
            destination: initialDestination,
            originLat: initialOriginCoord[0],
            originLng: initialOriginCoord[1],
            destinationLat: initialDestinationCoord[0],
            destinationLng: initialDestinationCoord[1],
            coordinates: true
        }
    }

    render(){

        const originStr = encodeURIComponent((this.state?.origin ?? "").replace(/[^a-zA-Z0-9-+ ]/g, "").replace(/ /g, "+")).replace(/%2B/g, "+");
        const destinationStr = encodeURIComponent((this.state?.destination ?? "").replace(/[^a-zA-Z0-9-+ ]/g, "").replace(/ /g, "+")).replace(/%2B/g, "+");

        const originCoord = `${this.state.originLng},${this.state.originLat}`;
        const destinationCoord = `${this.state.destinationLng},${this.state.destinationLat}`;

        const origin = this.state.coordinates ? originCoord : originStr;
        const destination = this.state.coordinates ? destinationCoord : destinationStr;


        return (
            <div className="health row py-5">
                <div className="col-10 col-lg-4 my-lg-5 mx-lg-5">
                    <h3 className="section-header my-4">Directions API</h3>
                    <p>
                        The directions endpoint provides point to point directions from an origin to a destination.
                        Both the origin and destination can be in the form of a coordinate pair, or an address or query.
                        Coordinates should take the form <span>LONGITUDE,LATITUDE</span> while queries or addresses will be passed to
                        the <a href="/geocoder">geocoder</a> to be converted into coordinates.
                    </p>
                    <p>
                        Routing calculations are done primarily by <a href="https://openrouteservice.org/">Open Route Service</a>.
                        These calculations will self-cache, and thus directions in areas of the map which have been recently calculated
                        will return results significantly faster.
                    </p>
                    <hr />
                    <div className="mt-4 mb-3 row d-flex justify-content-center">
                        <FloatingLabel
                            className={`col-10 col-lg-5 mx-2 my-1 my-lg-0 ${this.state.coordinates ? "" : "darken"}`}
                            label="Origin Latitude"
                            initialValue={initialOriginCoord[0]}
                            onFocus={(_event) => this.setState({coordinates: true})}
                            onChangeDelay={750}
                            onChange={(event) => {
                                this.setState({
                                    originLat: (event.target as HTMLInputElement).value,
                                    coordinates: true
                                });
                            }} />
                            <FloatingLabel
                                className={`col-10 col-lg-5 mx-2 my-1 my-lg-0 ${this.state.coordinates ? "" : "darken"}`}
                                label="Origin Longitude"
                                initialValue={initialOriginCoord[1]}
                                onFocus={(_event) => this.setState({coordinates: true})}
                                onChangeDelay={750}
                                onChange={(event) => {
                                    this.setState({
                                        originLng: (event.target as HTMLInputElement).value,
                                        coordinates: true
                                    });
                                }} />
                    </div>
                    <div className="mb-4 mt-3 row d-flex justify-content-center">
                        <FloatingLabel
                            className={`col-10 col-lg-5 mx-2 my-1 my-lg-0 ${this.state.coordinates ? "" : "darken"}`}
                            label="Destination Latitude"
                            initialValue={initialDestinationCoord[0]}
                            onFocus={(_event) => this.setState({coordinates: true})}
                            onChangeDelay={750}
                            onChange={(event) => {
                                this.setState({
                                    destinationLat: (event.target as HTMLInputElement).value,
                                    coordinates: true
                                });
                            }} />
                            <FloatingLabel
                                className={`col-10 col-lg-5 mx-2 my-1 my-lg-0 ${this.state.coordinates ? "" : "darken"}`}
                                label="Destination Longitude"
                                initialValue={initialDestinationCoord[1]}
                                onFocus={(_event) => this.setState({coordinates: true})}
                                onChangeDelay={750}
                                onChange={(event) => {
                                    this.setState({
                                        destinationLng: (event.target as HTMLInputElement).value,
                                        coordinates: true
                                    });
                                }} />
                    </div>
                    <hr />

                    <FloatingLabel
                        className={`mt-4 mb-3 ${this.state.coordinates ? "darken" : ""}`}
                        label="Origin"
                        initialValue={initialOrigin}
                        onFocus={(_event) => this.setState({coordinates: false})}
                        onChangeDelay={1000}
                        onChange={(event) => {
                            this.setState({
                                origin: (event.target as HTMLInputElement).value,
                                coordinates: false
                            });
                        }} />
                    <FloatingLabel
                        label="Destination"
                        className={`mb-4 ${this.state.coordinates ? "darken" : ""}`}
                        initialValue={initialDestination}
                        onFocus={(_event) => this.setState({coordinates: false})}
                        onChangeDelay={1000}
                        onChange={(event) => {
                            this.setState({
                                destination: (event.target as HTMLInputElement).value,
                                coordinates: false
                            });
                        }} />
                </div>
                <div className="col-10 col-lg-7 my-lg-5 mx-lg-3">
                    <Code raw={`$curl -X GET "${query}YOUR_API_KEY&origin=${origin}&destination=${destination}"`} />
                    <GetJSONComponent query={`${query}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}&origin=${origin}&destination=${destination}`}/>
                </div>
            </div>
        );
    }
}
