import React from 'react';
import './map.css';
import Code from "../code/Code";

import GoopterMapReact, {TileServerTemplate, Marker} from "goopter-map-react";
import FloatingLabel from "react-bootstrap-floating-label";

import "dotenv/config";

interface IProps{}
interface IState{
    lat: number,
    lng: number,
    zoom: number,
    mlat: number,
    mlng: number,
    lock: boolean
}

class Map extends React.Component<IProps, IState>{

    constructor(props: IProps){
        super(props);

        this.state = {
            lat: 49.213189,
            lng: -122.988504,
            mlat: 49.213189,
            mlng: -122.988504,
            zoom: 15,
            lock: false
        }
    }

    render(){

        const mapReactCode = <GoopterMapReact
            bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOPTER_MAP_KEY ?? "",
                server: TileServerTemplate.MAP
            }}
            zoom={this.state.zoom}
            center={[this.state.lat, this.state.lng]}
            
        >
            <Marker lat={this.state.mlat} lng={this.state.mlng} />
        </GoopterMapReact>;

        const mapFilter = (raw: string) => raw
                                    .replace(/ *key ?: ?['"][^'"]+['"]/, "key : 'YOUR_API_KEY_HERE'")
                                    .replace(/ *yesIWantToUseGoogleMapApiInternals={[a-zA-Z]+}\n/, "")
                                    .replace(/ *defaultZoom={[0-9]*}\n/, "")
                                    .replace(/ *defaultCenter={\[[^\]]*\]}\n/, "")
                                    .replace(/ *server ?: ?'/, "server : \n'")
                                    .replace(/ *onCenterChange.*{}}\n/, "")
                                    .replace(/ *onZoomChange.*{}}\n/, "")
                                    .replace(/<[a-z]\n/, "<GoopterMapReact\n")
                                    .replace(/<[a-z]\n/, "<Marker\n")
                                    .replace(/ *directRender\n/, "")
                                    .replace(/<\/[a-z]>/, "</GoopterMapReact>")

        return (
            <div className="map row align-items-start py-5">
                <div className="controls col-10 col-lg-4 mb-5 mx-lg-3">
                    <h3 className="section-header my-4">Goopter Map React</h3>
                    <p>
                        The Goopter Map React is an npm package which provides a
                        React component for rendering frontend maps. On the front-end it
                        uses <a href="https://leafletjs.com/">Leaflet JS</a> to stitch tiles together,
                        and on the backend it connects to a tile rendering server. The tile rendering
                        server runs a postgresSQL database, and will cache tiles it has already rendered.
                        It's a modified version of this <a href="https://github.com/Overv/openstreetmap-tile-server">docker image</a>,
                        and more information about the specifics of the backend can be found at <a href="https://switch2osm.org/">switch2osm.org</a>.
                    </p>
                    <p>
                        The GoopterMapReact component takes a <span>bootstrapURLKeys</span> prop which contains the
                        API key to use, as well as the specific tile server endpoint for individual tiles. Shorthands
                        for QA, DEV, and MAP servers are available under the <span>{"{TileServerTemplate}"}</span> enum import.
                        Additional, live updating props such as <span>center</span>, <span>zoom</span>, and <span>enableControls</span> are
                        available. GoopterMapReact is developed in typescript and as such full time definations will enable
                        prop and method discovery in-editor. Markers may be passed as prop children to the component. These markers
                        must have a <span>lat</span> and <span>lng</span> prop determining where the marker is rendered. Markers can be
                        completely custom, however a default <span>{"{Marker}"}</span> component may be imported from the package.
                    </p>
                    <hr />
                    <div className="row my-3">
                        <FloatingLabel className="col-4" label="Latitude" initialValue={`${this.state.lat}`} onChangeDelay={500} onChange={event => this.setState({
                            lat: parseFloat((event.target as HTMLInputElement).value),
                            lock: true
                        })} />
                        <FloatingLabel className="col-4" label="Longitude" initialValue={`${this.state.lng}`} onChangeDelay={500} onChange={event => this.setState({
                            lng: parseFloat((event.target as HTMLInputElement).value),
                            lock: true
                        })} />
                        <FloatingLabel className="col-4" label="Zoom" initialValue={`${this.state.zoom}`} onChangeDelay={300} onChange={event => {
                            const float = parseFloat((event.target as HTMLInputElement).value);
                            this.setState({
                                zoom: isNaN(float) ? 1 : float
                            });
                        }} />
                    </div>
                    <hr />
                    <div className="row my-3">
                        <FloatingLabel className="col-6" label="Marker Latitude" initialValue="49.213189" onChange={event => this.setState({
                            mlat: parseFloat((event.target as HTMLInputElement).value)
                        })} />
                        <FloatingLabel className="col-6" label="Marker Longitude" initialValue="-122.988504" onChange={event => this.setState({
                            mlng: parseFloat((event.target as HTMLInputElement).value)
                        })} />
                    </div>
                    <hr />
                    <div className="col-12">
                        <Code raw={`import GoopterMapReact, { Marker } from "goopter-map-react";`} />
                        <Code filter={mapFilter}>{mapReactCode}</Code>
                    </div>
                </div>
                <div className="map-canvas col-10 col-lg-7 my-4 mx-lg-3">{
                    mapReactCode
                }</div>
            </div>
        );
    }
}

export default Map;
