import React from 'react';
import './geocoder.css';
import "dotenv/config";
import FloatingLabel from "react-bootstrap-floating-label";
import {GetJSONComponent} from "../utility/getJSON";
import Code from '../code/Code';

interface IProps{}
interface IState{
    address?: string,
    addressLat?: string,
    addressLng?: string,
    coordinates: boolean
}

const initialAddress = "7777 Royal Oak Ave, Burnaby, BC V5J 4K2";

const initialAddressCoord = ["49.213167", "-122.988492"];

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const query = `${serverPrefix}/api/geocoder/json?key=`;

export default class Geocoder extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);

        this.state = {
            address: initialAddress,
            addressLat: initialAddressCoord[0],
            addressLng: initialAddressCoord[1],
            coordinates: true
        }
    }

    render(){

        const addressStr = encodeURIComponent((this.state?.address ?? "").replace(/[^a-zA-Z0-9-+ ]/g, "").replace(/ /g, "+")).replace(/%2B/g, "+");
        const addressCoord = `${this.state.addressLng},${this.state.addressLat}`;
        const address = this.state.coordinates ? addressCoord : addressStr;

        return (
            <div className="health row py-5">
                <div className="col-10 col-lg-4 my-lg-5 mx-lg-5">
                    <h3 className="section-header my-4">Geocoder API</h3>
                    <p>
                        The geocoder endpoint provides coordinate or address query lookup.
                        The query can be in the form of a coordinate pair, or an address.
                        Coordinates should take the form <span>LONGITUDE,LATITUDE</span>.
                        The geocoder will convert these queries or coordinate points into complete addresses.
                    </p>
                    <p>
                        The geocoder endpoint connects on the backend to an instance of the <a href="https://github.com/pelias/pelias">Pelias</a> geocoder.
                        Pelias contains an elasticsearch search engine and document database containing
                        records for all addresses, businesses, buildings, roads, and other geographical features.
                        Querying the geocoder endpoint will index into this document database.
                    </p>
                    <hr />
                    <div className="mt-4 mb-3 row d-flex justify-content-center">
                        <FloatingLabel
                            className={`col-10 col-lg-5 mx-2 my-1 my-lg-0 ${this.state.coordinates ? "" : "darken"}`}
                            label="Latitude"
                            initialValue={initialAddressCoord[0]}
                            onFocus={(_event) => this.setState({coordinates: true})}
                            onChangeDelay={750}
                            onChange={(event) => {
                                this.setState({
                                    addressLat: (event.target as HTMLInputElement).value,
                                    coordinates: true
                                });
                            }} />
                            <FloatingLabel
                                className={`col-10 col-lg-5 mx-2 my-1 my-lg-0 ${this.state.coordinates ? "" : "darken"}`}
                                label="Longitude"
                                initialValue={initialAddressCoord[1]}
                                onFocus={(_event) => this.setState({coordinates: true})}
                                onChangeDelay={750}
                                onChange={(event) => {
                                    this.setState({
                                        addressLng: (event.target as HTMLInputElement).value,
                                        coordinates: true
                                    });
                                }} />
                    </div>
                    <hr />

                    <FloatingLabel
                        className={`mt-4 mb-3 ${this.state.coordinates ? "darken" : ""}`}
                        label="Address Query"
                        initialValue={initialAddress}
                        onFocus={(_event) => this.setState({coordinates: false})}
                        onChangeDelay={1000}
                        onChange={(event) => {
                            this.setState({
                                address: (event.target as HTMLInputElement).value,
                                coordinates: false
                            });
                        }} />

                </div>
                <div className="col-10 col-lg-7 pt-lg-4 my-lg-5 mx-lg-3">
                    <Code raw={`$curl -X GET "${query}YOUR_API_KEY&address=${address}"`} />
                    <GetJSONComponent query={`${query}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}&address=${address}`}/>
                </div>
            </div>
        );
    }
}
