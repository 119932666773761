import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './home/Home';
import Map from './map/Map';
import Health from './health/Health';
import Directions from './directions/Directions';
import Distance from './distance/Distance';
import Geocoder from './geocoder/Geocoder';
import Places from './places/Places';
import Autocomplete from './autocomplete/Autocomplete';
import Store from './store/Store';
import Selector from './selector/Selector';
import DirectionsMap from './directionsmap/DirectionsMap';
import reportWebVitals from './reportWebVitals';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route exact path="/"><Home /></Route>
                <Route exact path="/store"><Store /></Route>
                <Route exact path="/selector"><Selector /></Route>
                <Route path="/map-directions"><DirectionsMap /></Route>
                <Route exact path="/map"><Map /></Route>
                <Route exact path="/health"><Health /></Route>
                <Route exact path="/directions"><Directions /></Route>
                <Route exact path="/distance"><Distance /></Route>
                <Route exact path="/geocoder"><Geocoder /></Route>
                <Route exact path="/place"><Places /></Route>
                <Route exact path="/autocomplete"><Autocomplete /></Route>
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
