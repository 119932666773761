import React from "react";
import "./code.css";

import reactElementToJSXString from 'react-element-to-jsx-string';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { xonokai } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface IProps{
    filter: (raw: string) => string,
    react?: React.ReactNode
    raw?: string
}
interface IState{}

export default class Code extends React.Component<IProps, IState>{

    static defaultProps = {
        filter: (raw: string) => raw,
    }

    render(){
        const raw = this.props.raw ?? reactElementToJSXString(this.props.react ?? this.props.children);
        const filtered = this.props.filter(raw);
        return <SyntaxHighlighter
            wrapLongLines={true}
            language="javascript"
            style={xonokai}>{
            filtered
        }</SyntaxHighlighter>
    }
}
