import React from 'react';
import './home.css';
import GoopterMapReact, { Marker, TileServerTemplate } from "goopter-map-react";

const text = "7777 Royal Oak Ave, Burnaby, BC, CA, V5J 4K2";
const coords = [49.213189, -122.988704];

function Home() {
    return (
        <div className="home">
            <header>
                <h1>Goopter </h1><h1>Map API Demos</h1>
            </header>
            <nav>
                <div>
                    <a href="/store">Store Address Selector</a>
                    <a href="/selector">Map Address Selector</a>
                    <a href="/map-directions">Visual Map Directions</a>
                </div>
                <div>
                    <a href="/map">Goopter Map React</a>
                    <a href="/health">Health Check</a>
                    <a href="/directions">Directions</a>
                    <a href="/distance">Distance</a>
                    <a href="/geocoder">Geo Coder</a>
                    <a href="/place">Place</a>
                    <a href="/autocomplete">Autocomplete</a>
                </div>
            </nav>
            <div>
                <GoopterMapReact
                    bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOPTER_MAP_KEY ?? "",
                        server: TileServerTemplate.MAP
                    }}
                    zoom={17}
                    center={coords}
                >
                    <Marker lat={coords[0]} lng={coords[1]}>{text}</Marker>
                </GoopterMapReact>
                <div id="overlay-text"><div><h6>{text}</h6></div></div>
            </div>
        </div>
    );
}

export default Home;
