import React from 'react';
import './selector.css';

import GoopterMapReact, {TileServerTemplate, Marker} from "goopter-map-react";
import axios from 'axios';


interface IProps{}
interface IState{
    address?: string,
    coords: [number, number]
}

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const geocoder = `${serverPrefix}/api/geocoder/json?key=`;
const place = `${serverPrefix}/api/place?key=`;

class Selector extends React.Component<IProps, IState>{

    constructor(props: IProps){
        super(props);

        this.state = {
            coords: [49.213196327019396, -122.98874742500924],
            address: "Goopter eCommerce Solutions"
        }
    }
    render(){
        return (
            <div>
                {
                this.state.address ? <>
                    <div className="address-display">{
                        this.state.address
                    }</div>
                </> : <></>
                }
                <div className="map-store-canvas">
                    <GoopterMapReact
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOPTER_MAP_KEY ?? "",
                            server: TileServerTemplate.MAP
                        }}
                        onClick={(coords: {
                            lat: number,
                            lng: number
                        }) => {
                            axios.get(`${geocoder}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}&address=${coords.lng ?? ""},${coords.lat ?? ""}`).then(res => {
                                const id = res.data.results && res.data.results.length && res.data.results.length > 0 ? res.data.results[0].place_id ?? "" : "";
                                axios.get(`${place}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}&ids=${id}`).then(res => {
                                    const address = res.data.features && res.data.features.length && res.data.features.length > 0 ? res.data.features[0].properties?.label ?? "" : "";
                                    this.setState({
                                        address,
                                        coords: [coords.lat, coords.lng]
                                    });
                                }).catch(console.error);
                            }).catch(console.error);
                        }}
                        defaultZoom={15}
                        defaultCenter={[49.213189, -122.988504]}
                    >
                        <Marker lat={this.state.coords[0]} lng={this.state.coords[1]}>{this.state.address}</Marker>
                    </GoopterMapReact>
                </div>
            </div>
        );
    }
}

export default Selector;
