import axios, { AxiosError } from "axios";
import React from "react";
import Code from "../code/Code";

const getJSON = (query: string): Promise<string> => {
    return new Promise<string>((resolve, _reject) => {
        axios.get(query).then(resp => {
            resolve(JSON.stringify(
                (typeof resp.data === "object") ? resp.data : JSON.parse(resp.data.toString().replace(/\\/g, ""))
            , null, 4));
        }).catch((err: AxiosError) => {
            resolve(JSON.stringify(err?.response?.data ?? {}, null, 4));
        });
    })
}
export default getJSON;

interface IProps{
    query: string,
}
interface IState{
    data: string
}

export class GetJSONComponent extends React.Component<IProps, IState>{

    constructor(props: IProps){
        super(props);

        this.state = {
            data: "Loading..."
        }
    }

    componentDidMount(){
        getJSON(this.props.query).then(data => this.setState({data}));
    }

    componentDidUpdate(prevProps: IProps){
        if(prevProps.query !== this.props.query){
            this.setState({
                data: "Loading..."
            });
            getJSON(this.props.query).then(data => this.setState({data}));
        }
    }

    render(){
        return <Code raw={this.state.data} />;
    }
}
