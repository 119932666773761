import React from 'react';
import './autocomplete.css';
import "dotenv/config";
import FloatingLabel from "react-bootstrap-floating-label";
import Code from '../code/Code';
import MultiSelect, {Option} from "../utility/multiSelect";

const initialLat = "49.213098";
const initialLng = "-122.989172";
const initialRad = "5000";
const initialCountry = "CA";
const initialQuery = "7777 Royal Oak";

interface RequestBase{
    input: string,
    componentRestrictions?: {
        country: string
    },
    radius?: number,
    types?: string[]
}
interface Request extends RequestBase{
    location?: {
        lat: () => number,
        lng: () => number,
    }
}
interface RequestRasterized extends RequestBase{
    location?: {
        lat: string,
        lng: string,
    }
}

interface IProps{}
interface IState{
    query?: string
    flat?: string
    flng?: string
    radius?: string,
    country?: string,
    types: Option[],
    request: Request,
    result?: any[],
    resultStatus?: any
    resultStatusDisplay?: string,
    detectChange: number
}

const rasterizeRequest = (request: Request): RequestRasterized => {
    if(request.location && typeof request.location.lat !== "string"){
        (request as any).location = {
            lat: `() => ${request.location.lat()}`,
            lng: `() => ${request.location.lng()}`,
        };
    }
    return request as RequestRasterized;
}

const buildDemoJS = (request: Request): string => {
    const baseJSON = JSON.stringify(rasterizeRequest(request), null, 4);

    return `const service = new window.AutocompleteService();
const request = ${baseJSON};

service.getPlacePredictions(request , (data, status) => {
    console.dir(\`Status: \${status}\`);
    console.dir(data);
});`;
}

const autocompleteService = new (window as any).AutocompleteService();

export default class Autocomplete extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);

        this.state = {
            types: [],
            query: initialQuery,
            flat: initialLat,
            flng: initialLng,
            radius: initialRad,
            country: initialCountry,
            detectChange: 0,
            request: {
                input: ""
            }
        }

        this.controlledInputFactory = this.controlledInputFactory.bind(this);
    }

    componentDidMount(){
        this.setState({
            resultStatusDisplay: `Loading...`,
            result: undefined,
            detectChange: this.state.detectChange + 1
        });
    }

    componentDidUpdate(_prevProps: IProps, prevState: IState){
        if(
            prevState.query !== this.state.query ||
            prevState.flat !== this.state.flat ||
            prevState.flng !== this.state.flng ||
            prevState.radius !== this.state.radius ||
            prevState.country !== this.state.country ||
            prevState.types.length !== this.state.types.length ||
            prevState.detectChange !== this.state.detectChange
        ){
            const request: any = {
                input: this.state.query ?? ""
            };
            if(
                this.state.flat &&
                this.state.flng &&
                !!parseFloat(this.state.flat) &&
                !!parseFloat(this.state.flng)
            ){
                request.location = {
                    lat: () => parseFloat(this.state.flat as string) as number,
                    lng: () => parseFloat(this.state.flng as string) as number
                }
                if(this.state.radius && !!parseInt(this.state.radius)){
                    request.radius = parseInt(this.state.radius as string) as number
                }
            }
            if(this.state.types.length > 0){
                request.types = this.state.types.map(type => type.name);
            }
            else{
                delete request.types;
            }

            if(this.state.country){
                request.componentRestrictions = {
                    country: this.state.country
                };
            }
            else{
                delete request.componentRestrictions;
            }

            this.setState({
                request
            });

            if((this.state.query ?? "").length > 0){
                this.setState({
                    resultStatusDisplay: `Loading...`,
                    result: undefined
                })
                autocompleteService.getPlacePredictions(request , (result: any, resultStatus:  any) => {
                    this.setState({
                        result,
                        resultStatus,
                        resultStatusDisplay: `Status: ${resultStatus}`
                    });
                });
            }
        }
    }

    controlledInputFactory(key: string){
        return (event: React.FormEvent<HTMLInputElement>) => {
            const state: any = {}
            state[key] = (event.target as HTMLInputElement).value;
            this.setState(state);
        }
    }

    render(){
        return (
            <div className="health row py-5">
                <div className="col-10 col-lg-4 my-lg-5 mx-lg-5 d-flex flex-column align-items-center justify-content-center">
                    <h3 className="section-header my-4">Places Autocomplete Service</h3>
                    <p>
                        The Places Autocomplete Service is an implementation of
                        the <a href="https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service">Google Places Autocomplete Service</a>,
                        leveraging Pelias' <a href="https://github.com/pelias/parser">Parser</a> and <a href="https://github.com/pelias/documentation/blob/master/autocomplete.md">Autocomplete</a>.
                        Projects using the Autocomplete Service must include a script tag in the raw HTML
                        to load the compiled library. Including this script will provide access to a number of
                        global objects and services. Full documentation can be found on the <a href="https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service">Google Places Autocomplete Service</a> page,
                        and the <a href="https://wiki.goopter.com/index.php?title=JS_Places_Services">Goopter Wiki</a> page.
                    </p>
                    <p>
                        All requests must have an <span>input</span> parameter, with the text to be queried for. Additionally,
                        a focus coordinate and radius may be applied to filter by geolocation, a country code to filter by country,
                        or a specific type of record. If no record types are applied, records of all types will be queried for.
                    </p>
                    <hr />
                    <FloatingLabel
                        initialValue={initialQuery}
                        className={`col-12 col-lg-10 my-4`}
                        label="Query"
                        onChangeDelay={1000}
                        onChange={this.controlledInputFactory("query")} />
                    <hr />
                    <div className="col-12 my-2 mx-0 mt-lg-4 row d-flex align-items-center justify-content-center">
                        <FloatingLabel
                            initialValue={initialLat}
                            className={`col-12 col-lg-5 mx-lg-2 my-2 my-lg-0`}
                            label="Focus Latitiude"
                            onChangeDelay={750}
                            onChange={this.controlledInputFactory("flat")} />
                        <FloatingLabel
                            initialValue={initialLng}
                            className={`col-12 col-lg-5 mx-lg-2 my-2 my-lg-0`}
                            label="Focus Longitude"
                            onChangeDelay={750}
                            onChange={this.controlledInputFactory("flng")} />
                    </div>
                    <FloatingLabel
                        initialValue={initialRad}
                        className={`col-12 col-lg-10 my-3 mb-4`}
                        label="Maximum Radius"
                        onChangeDelay={750}
                        onChange={this.controlledInputFactory("radius")} />
                    <hr />
                    <FloatingLabel
                        initialValue={initialCountry}
                        className={`col-12 col-lg-10 my-4`}
                        label="Country Code"
                        onChangeDelay={750}
                        onChange={this.controlledInputFactory("country")} />
                    <hr className="mb-4" />
                    <MultiSelect
                        placeholder="Types (Empty for all)"
                        options={[
                            {name: "geocode", id: 0},
                            {name: "address", id: 1},
                            {name: "establishment", id: 2},
                            {name: "regions", id: 3},
                            {name: "cities", id: 4}
                        ]}
                        onChange={(types: Option[]) => {
                            const request = this.state.request;
                            request.types = types.map(type => type.name);
                            this.setState({
                                types,
                                request,
                                detectChange: this.state.detectChange + 1
                            });

                        }}
                    />
                </div>
                <div className="col-10 col-lg-7 pt-lg-4 my-lg-5 mx-lg-3">
                    <Code><script src="https://mapsvc1.goopter.com/api/geo?key=YOUR_API_KEY"></script></Code>
                    <Code
                        filter={(raw: string) => raw.replace(/( *)"(lat|lng)": ?"\(\) => ([0-9.-]*)"/g, (_match, $1, $2, $3) => `${$1}"${$2}": () => ${$3}`)}
                        raw={buildDemoJS(this.state.request)}
                    />
                    {
                        !!this.state.resultStatusDisplay ? <Code raw={this.state.resultStatusDisplay}/> : <></>
                    }{
                        !!this.state.result ? <Code raw={JSON.stringify(this.state.result, null, 4)}/> : <></>
                    }
                </div>
            </div>
        );
    }
}
