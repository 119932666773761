import React from 'react';
import './store.css';

import { Link } from 'react-router-dom';
import GoopterMapReact, {TileServerTemplate, Marker, AddressSelector, PlacesServerTemplate, AutocompleteServerTemplate} from "goopter-map-react";
import axios from 'axios';

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const API_KEY = process.env.REACT_APP_GOOPTER_MAP_KEY ?? "";
const placesEndpoint = `${serverPrefix}/api/place?key=${API_KEY}&ids=`;

interface IProps { }
interface IState {
    lat: number,
    lng: number,
    mlat: number,
    mlng: number,
    lock: boolean,
    showMarker: boolean,
    label: string,
    city: string,
    region: string,
    country: string
}

class Store extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);

        this.state = {
            lat: 49.213189,
            lng: -122.988504,
            mlat: 49.213189,
            mlng: -122.988504,
            lock: false,
            showMarker: false,
            label: "",
            city: "",
            region: "",
            country: ""
        }
    }

    closingButtonHandler() {
        this.setState({
            label: "",
            city: "",
            region: "",
        });
    }

    setSubtText(city: string, region: string, country: String) {
        return (city === undefined) ? `${region}, ${country}` : `${city}, ${region}, ${country}`
    }

    render() {
        return (

            <div className="store-container">

                <div className="address-search">
                    <AddressSelector
                        bootstrapURLKeys={{
                            placesServer: PlacesServerTemplate.MAP,
                            autocompleteServer: AutocompleteServerTemplate.MAP,
                            key: API_KEY
                        }}
                        onClosing={() => {
                            this.closingButtonHandler()
                        }}
                        onSelection={(id: string) => {
                            axios.get(`${placesEndpoint}${id}`).then(res => {
                                const lat: number = res.data.features[0].geometry.coordinates[1];
                                const lng: number = res.data.features[0].geometry.coordinates[0];
                                const label: string = res.data.features[0].properties?.name;
                                const city: string = res.data.features[0].properties.locality;
                                const region: string = res.data.features[0].properties.region_a;
                                const country: string = res.data.features[0].properties.country;

                                this.setState({
                                    showMarker: true,
                                    mlat: lat,
                                    mlng: lng,
                                    lat,
                                    lng,
                                    lock: true,
                                    label,
                                    city,
                                    region,
                                    country
                                });
                                setTimeout(() => this.setState({
                                    lock: false
                                }), 250);

                            }).catch(console.error);
                        }}
                        buttonClassName=""
                        buttonColor="#282c34"
                        
                    />
                </div>
                <div className="map-store-canvas">
                    <GoopterMapReact
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOPTER_MAP_KEY ?? "",
                            server: TileServerTemplate.MAP
                        }}
                        defaultZoom={15}
                        center={[this.state.lat, this.state.lng]}
                    >{
                            this.state.showMarker ? <Marker lat={this.state.mlat} lng={this.state.mlng}>{this.state.label}</Marker> : <></>
                        }</GoopterMapReact>
                </div>
                {
                    this.state.label !== "" &&
                    <div className="directions-container">
                        <div className="directions-label">{this.state.label}</div>
                        <div className="directions-city-region">{this.setSubtText(this.state.city, this.state.region, this.state.country)}</div>
                        <Link to={{
                            pathname: `/map-directions/`
                        }}>

                            {/* Sending lat/lng/label as URL query string to be potentially used in Directions Map Component
                        
                        <Link to={{
                            pathname: `/map-directions/q?${this.state.lat},${this.state.lng},${this.state.label}`
                        }}> */}
                            <button type="button" className="btn btn-primary directions-button btn-tablet">Directions</button>
                        </Link>
                    </div>
                }
            </div>
        );
    }
}

export default Store;
