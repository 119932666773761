import React from 'react';
import './health.css';
import Code from "../code/Code";
import "dotenv/config";
import { GetJSONComponent } from "../utility/getJSON";

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const query = `${serverPrefix}/api/health?key=`;

export default class Health extends React.Component {
    render(){
        return (
            <div className="health row py-5">
                <div className="col-10 col-lg-4 my-lg-5 mx-lg-5">
                    <h3 className="section-header my-4">Health Check</h3>
                    <p>
                        Map services and API endpoints are only available once the health check endpoint
                        displays "ready". To check the health status of a given deployment, query the health check.
                    </p>
                    <p>
                        The health check is a direct redirect to the internal health status calculated by
                        the <a href="https://openrouteservice.org/">Open Route Service</a>. This check will return
                        false for a number of hours after initial start, as the service must generate the graphs
                        used for routing calculations.
                    </p>
                </div>
                <div className="col-10 col-lg-7 pt-lg-4 my-lg-5 mx-lg-3">
                    <Code>{`$curl -X GET "${query}YOUR_API_KEY"`}</Code>
                    <GetJSONComponent query={`${query}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}`} />
                </div>
            </div>
        );
    }
}
