import React from 'react';
import './directionsmap.css';
import GoopterMapReact, {TileServerTemplate, Directions, Marker, PlacesServerTemplate, DirectionsServerTemplate, DirectionsPanel, DirectionsData, AutocompleteServerTemplate} from "goopter-map-react";
import axios from 'axios';
interface IProps {
}
interface IState {
    origin?: {
        lat: number,
        lng: number,
        label: string
    },
    destination?: {
        lat: number,
        lng: number,
        label: string
    },
    coords: {
        lat: number,
        lng: number
    }[],
    bounds?: [[number, number], [number, number]],
    clickedId?: string
}

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const geocoder = `${serverPrefix}/api/geocoder/json?key=`;

class DirectionsMap extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);


        this.state = {
            coords: []
        }
    }

    // closingButtonHandler(id: string) {
    //     console.log('..............., ', id)
    // }
    


    render() {
        return (
            <div className="directionsMap">

                <div className="directions-search">

                    <DirectionsPanel
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOPTER_MAP_KEY ?? "",
                            placesServer: PlacesServerTemplate.MAP,
                            directionsServer: DirectionsServerTemplate.MAP,
                            autocompleteServer: AutocompleteServerTemplate.MAP
                        }}
                        moveToCoordsCallback={(coord: {
                            lat: number,
                            lng: number
                        }) => {
                            this.setState({
                                bounds: [
                                    [coord.lat - 0.001, coord.lng - 0.001],
                                    [coord.lat + 0.001, coord.lng + 0.001],
                                ]
                            });
                        }}
                        onSelection={(data: DirectionsData) => {
                            if ("geometry" in data) {

                                const diffX = data.bounds.northeast.lng - data.bounds.southwest.lng;
                                const diffY = data.bounds.northeast.lat - data.bounds.southwest.lat;


                                const bounds = {
                                    northeast: {
                                        lat: data.bounds.northeast.lat + (diffY * 0.005),
                                        lng: data.bounds.northeast.lng + (diffX * 0.01)
                                    },
                                    southwest: {
                                        lat: data.bounds.southwest.lat - (diffY * 0.005),
                                        lng: data.bounds.southwest.lng - (diffX * 0.5)
                                    },
                                }
                                this.setState({
                                    coords: data.geometry,
                                    origin: data.origin,
                                    bounds: [
                                        [bounds.southwest.lat, bounds.southwest.lng],
                                        [bounds.northeast.lat, bounds.northeast.lng],
                                    ]
                                });
                            }
                            else {
                                if ("origin" in data) {
                                    this.setState({
                                        origin: data.origin
                                    });
                                }
                                else {
                                    this.setState({
                                        destination: data.destination
                                    });
                                }
                            }
                        }}
                        externalRequestedId={this.state.clickedId}
                        // onClosing={(id) => this.closingButtonHandler(id)}
                    />
                </div>
                <div className="map-store-canvas">
                    <GoopterMapReact
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOPTER_MAP_KEY ?? "",
                            server: TileServerTemplate.MAP
                        }}
                        defaultZoom={15}
                        defaultCenter={[49.213189, -122.988504]}
                        bounds={this.state.bounds}
                        onClick={(coords: {
                            lat: number,
                            lng: number
                        }) => {
                            axios.get(`${geocoder}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}&address=${coords.lng ?? ""},${coords.lat ?? ""}`).then(res => {
                                const clickedId = res.data.results && res.data.results.length && res.data.results.length > 0 ? res.data.results[0].place_id ?? "" : "";
                                this.setState({
                                    clickedId
                                });
                            }).catch(console.error);
                        }}
                    >{
                            this.state.coords.length > 1
                                ? <Directions positions={this.state.coords} origin={this.state.origin?.label} destination={this.state.destination?.label} />
                                : (
                                    this.state.origin
                                        ? <Marker lat={this.state.origin.lat} lng={this.state.origin.lng}>{this.state.origin.label}</Marker>
                                        : (
                                            this.state.destination
                                                ? <Marker lat={this.state.destination.lat} lng={this.state.destination.lng}>{this.state.destination.label}</Marker>
                                                : <></>
                                        )
                                )
                        }</GoopterMapReact>
                </div>
            </div>
        );
    }
}

export default DirectionsMap;