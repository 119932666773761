import React from 'react';
import './places.css';
import "dotenv/config";
import FloatingLabel from "react-bootstrap-floating-label";
import {GetJSONComponent} from "../utility/getJSON";
import Code from '../code/Code';

interface IProps{}
interface IState{
    place?: string,
}

const initialPlace = "openstreetmap:address:way/551709297";

const serverPrefix = process.env.REACT_APP_SERVER_PREFIX ?? "http://localhost:8080";
const query = `${serverPrefix}/api/place?key=`;

export default class Place extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);

        this.state = {
            place: initialPlace,
        }
    }

    render(){

        const place = this.state.place ?? "";

        return (
            <div className="health row py-5">
                <div className="col-10 col-lg-4 my-lg-5 mx-lg-5">
                    <h3 className="section-header my-4">Place Lookup API</h3>
                    <p>
                        The place endpoint provides detailed geographical place lookup similar
                        to the <a href="/geocoder">geocoder</a>, but indexed by place ID. The place ID
                        is usually located in the <span>gid</span> or <span>place_id</span> field of a feature, or composed of
                        the <span>source</span>, <span>layer</span>, and <span>id</span> fields of an <span>ids</span> section.
                    </p>
                    <p>
                        The place endpoint connects on the backend to an instance of the <a href="https://github.com/pelias/pelias">Pelias</a> geocoder.
                        Pelias contains an elasticsearch search engine and document database containing
                        records for all addresses, businesses, buildings, roads, and other geographical features.
                        Querying the place endpoint will directly index into this document database.
                    </p>
                    <hr />
                    <FloatingLabel
                        className={`col-12 my-1 my-lg-0`}
                        label="Place ID"
                        initialValue={initialPlace}
                        onChangeDelay={750}
                        onChange={(event) => {
                            this.setState({
                                place: (event.target as HTMLInputElement).value
                            });
                        }} />
                    <hr />

                </div>
                <div className="col-10 col-lg-7 pt-lg-4 my-lg-5 mx-lg-3">
                    <Code raw={`$curl -X GET "${query}YOUR_API_KEY&ids=${place}"`} />
                    <GetJSONComponent query={`${query}${process.env.REACT_APP_GOOPTER_MAP_KEY ?? ""}&ids=${place}`}/>
                </div>
            </div>
        );
    }
}
